import React from 'react'
import styled from 'styled-components'

import LayoutDark from '../components/layoutDark'
import Seo from '../components/seo'
import { Colors, Fonts, TypeScale, media } from '../components/styles/variables'
import { Wrapper } from '../components/styles/grid'
import { PageHeading } from '../components/styles/elements'

import photographerPhoto from '../images/photographer-profile.jpg'

const Heading = styled(PageHeading)`
    ${media.greaterThan('medium')`
        color: ${Colors.light};
    `}
`

const AboutArticle = styled.article`
    grid-column: 2 / span 8;
    grid-row: 2;

    > h2 {
        font-family: ${Fonts.serif};
        font-size: ${TypeScale.two};
        line-height: 1.25;
        margin-bottom: 1em;

        ${media.greaterThan('medium')`
            font-size: ${TypeScale.three};
        `}
    }

    > h3 {
        font-family: ${Fonts.serif};
        font-size: ${TypeScale.two};
        line-height: 1.25;
        margin-bottom: 1em;
    }
`

const AboutImage = styled.div`
    background-image: url(${props => props.image});
    background-position: 50% 50%;
    background-size: auto 80%;
    width: 100%;
    height: 400px;
    margin: 0 0 20px;
    background-repeat: no-repeat;
    background-size: cover;

    ${media.lessThan('medium')`
        display: ${props => props.hideSmall ? 'none' : 'block'};
    `}
`

const AboutSidebar = styled.div`
    grid-column: 11 / span 5;
    grid-row: 2;
    text-align: right;

    > h2 {
        font-size: ${TypeScale.one};
        font-family: ${Fonts.serif};
    }
`

const AboutPage = () => (
    <LayoutDark>
        <Seo title='About' keywords={[`gatsby`, `application`, `react`, `blog`, `web developer`, `photographer`]} />

        <Wrapper as='main'>
            <Heading>About me</Heading>

            <AboutArticle>
                <p>
                    I’m a senior software engineer with over 10 years experience in full-stack and front-end web development. Over the years I’ve worked on several successful projects and with major companies and brands and given talks at conferences both nationally and internationally.
                </p>
                <p>
                    After several years as a developer the overriding aspect of my work is to make sure user needs are at the forefront of the decisions being made in the product. I’m best when working closely with designers, helping their vision become realised in the best way for users.
                </p>
                <p>
                    I most recently joined <a href="https://numan.com">Numan</a> as a Senior Frontend Engineer in 2022. In 2021, while working at <a href="https://kano.me">Kano</a> I was part of the team to deliver a World first musical hardware device in partnership with Kanye West, the <a href="https://www.stemplayer.com/">Donda Stem Player</a>, alongside which we delivered a cutting edge software platform to manage the device through the web using WebUSB.
                </p>

                <h2>Photography</h2>

                <p>I'm also a hobbiest photographer shooting everything from documentary projects to portraits and travel photos. In 2018 I took a break from software engineering to study an MA in Photojournalism and Documentary Photography graduating with a merit grade.</p>
                
                <p>My documentary work tells personal stories about people through compelling and thought provoking imagery. Recent projects have looked at mental health, particularly in myself and other men, exploring the topic through self-portraiture, still life, and documentary interviews.</p>

                <p>I've enjoyed finding ways of combining my experiences as a software developer and photography to create interesting stories and new ways of interacting with imagery. More to come on that in the future.</p>

                <h4>Shows</h4>

                <p>2018, <strong>Elephants in the Room</strong>, London College of Communication</p>
                <p>2018, <strong>I, The Other</strong>, Safehouse 1, Peckham.</p>
                <p>2018, <strong>MONOCHROME</strong>, PH21 Gallery, Budapest, Hungary</p>
            </AboutArticle>

            <AboutSidebar>
                <AboutImage image={photographerPhoto} />
            </AboutSidebar>
        </Wrapper>
    </LayoutDark>
)

export default AboutPage
